import React from 'react';

export default function TopBar() {
    return (
        <React.Fragment>
            <div style={{ color: 'white', fontSize: '20px', padding: '10px', backgroundColor: 'black', width: '100%', textAlign: 'center' }}>Take a stand against the genocide in Palestine, Congo and Ukraine</div>
        </React.Fragment>
    );
}

